const PrintImages = {
  brother: require('../imgs/prints/Brother.jpg'),
  buckInWheatField: require('../imgs/prints/BuckInWheatField.jpg'),
  contradictions: require('../imgs/prints/Contradictions.jpg'),
  danceOfTheHalfMinotaur: require('../imgs/prints/DanceOfTheHalfMinotaur.jpg'),
  explosionPickingUpThePieces: require('../imgs/prints/Explosion-PickingUpThePieces.jpg'),
  fiacre1: require('../imgs/prints/Fiacre1.jpg'),
  ishmael: require('../imgs/prints/Ishmael.jpg'),
  karplusHouses: require('../imgs/prints/KarplusHouses.jpg'),
  karplusPrintShoppeFront: require('../imgs/prints/KarplusPrintShoppeFront.jpg'),
  kittensBlessing: require('../imgs/prints/KittensBlessing.jpg'),
  lief1: require('../imgs/prints/Lief1.jpg'),
  mercy: require('../imgs/prints/Mercy.jpg'),
  seaSerpentDivided: require('../imgs/prints/SeaSerpentDivided.jpg'),
  teaCeremony: require('../imgs/prints/TeaCeremony.jpg'),
  theHangedMan: require('../imgs/prints/TheHangedMan.jpg'),
  theScreenPrinter: require('../imgs/prints/TheScreenPrinter.jpg'),
  theTigressDies: require('../imgs/prints/TheTigressDies.jpg'),
  theWorldIsQuietHere: require('../imgs/prints/TheWorldIsQuietHere.jpg'),
  unnaturalAnimals: require('../imgs/prints/UnnaturalAnimals.jpg'),
  
  // theTigerAndTheRabbit: require('../imgs/prints/TheTigerAndTheRabbit.jpg'),
  // tigershark: require('../imgs/prints/TigerShark.jpg'),
  // warriorRests: require('../imgs/prints/WarriorRests.jpg'),
};

export default PrintImages