import { useState } from "react";
export default function Beer({ end, newBeer }) {
    const [start, setStart] = useState(false);
    const [leftPoint, setLeftPoint] = useState(30);
    const [rightPoint, setRightPoint] = useState(60);
    const [slideNum, setSlideNum] = useState(15);
    const [shiftCount, setShiftCount] = useState(30);
    const [last, setLast] = useState(false);
    const [mouseHeld, setMouseHeld] = useState(false)

    //left high
    //31, 61
    //center
    //45, 45

    function slide() {
        if (slideNum > 0 && shiftCount === 0) {
            console.log(leftPoint, rightPoint)
            setSlideNum(slideNum - 1)
            setShiftCount((slideNum - 1) * 2)
        } else if (slideNum === 0 && !last) {
            setLeftPoint(45)
            setRightPoint(45)
            setLast(true)
            end()
        }
        if (shiftCount > 0) {
            console.log('run')
            let left = leftPoint, right = rightPoint, shift = shiftCount - 1
            if (slideNum % 2 === 0) {
                left--
                right++
            } else {
                left++
                right--
            }
            setShiftCount(shift)
            setLeftPoint(left)
            setRightPoint(right)
        }
    }

    if (!start) {
        setTimeout(() => { setStart(true) }, 900)
    }

    if (start && !last) {
        setTimeout(() => { slide() }, 5)
    }

    if (mouseHeld) {
        if (last){
            if (leftPoint < 147 && rightPoint < 147) {
                setTimeout(() => {
                    setLeftPoint(leftPoint + 1)
                    setRightPoint(rightPoint + 1)
                }, 5)
            }
            if (leftPoint === 147 || rightPoint === 147) {
                setMouseHeld(false)
                const beer = document.getElementById('beer')
                beer.animate([{ right: '50%' }, { right: '150%' }], { duration: 1000, iterations: 1 })
                setTimeout(() => { newBeer() }, 1000)
            }
        } else setMouseHeld(false)
    }

    // function drink() {
    //     if (leftPoint !== 0 && rightPoint !== 0) {
    //         setTimeout(() => {
    //             console.log('dring')
    //         }, 1000)
    //     }
    // }

    return (
        <svg id='beer' className="beer" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"
            onMouseDown={() => { setMouseHeld(true) }}
            onMouseUp={() => { console.log('up'); setMouseHeld(false) }}
        >
            <defs>
                <linearGradient>
                    <linearGradient id="glass-gradient" x1='0' x2='100%' >
                        <stop offset="0%" stopColor="#ffffff" />
                        <stop offset="16%" stopColor="#fffffff0" />
                        <stop offset="20%" stopColor="#dddddda0" />
                        <stop offset="35%" stopColor="#ffffff" />
                        <stop offset="55%" stopColor="#ddddddd0" />
                        <stop offset="68%" stopColor="#999999f0" />
                        <stop offset="70%" stopColor="#777777f0" />
                        <stop offset="72%" stopColor="#999999f0" />
                        <stop offset="97%" stopColor="#fffffff0" />
                        <stop offset="100%" stopColor="#77777770" />
                    </linearGradient>
                    <linearGradient id="glass-gradient-inner" x1='0' x2='100%' >
                        <stop offset="0%" stopColor="#eeeeeed0" />
                        <stop offset="10%" stopColor="#33333330" />
                        <stop offset="20%" stopColor="#ffffffe0" />
                        <stop offset="35%" stopColor="#33333330" />
                        <stop offset="50%" stopColor="#ffffffa0" />
                        <stop offset="70%" stopColor="#33333330" />
                        <stop offset="80%" stopColor="#ffffffc0" />
                        <stop offset="100%" stopColor="#a8a8a8a0" />
                    </linearGradient>
                </linearGradient>
            </defs>
            <path
                d="M 145 57 C 153.455 56.236 161.379 59.758 163 65 C 170.238 86.062 170.238 108.938 163 130 C 161.036 135.991 153.522 140.166 145 140 C 142.082 139.589 139.959 137.466 140 135 L 140 62
                C 139.959 59.534 142.082 57.411 145 57 Z M 45 20 C 45.641 17.552 47.552 15.641 50 15 C 77.741 6.804 107.259 6.804 135 15 C 137.448 15.641 139.359 17.552 140 20 L 140 38
                C 139.728 39.842 140.467 41.688 142 43 C 160.417 44.375 174.905 53.482 177 65 C 181.866 88.164 181.18 112.151 175 135 C 171.474 146.142 158.691 153.977 144 154
                C 142.173 154.418 140.69 155.53 140 157 L 140 172 C 139.976 175.397 138.043 178.49 135 180 C 107.706 190.855 77.294 190.855 50 180 C 46.957 178.49 45.024 175.397 45 172 L 45 20 Z"
                fill="url(#glass-gradient)"
                fillRule="evenOdd"
                stroke="#999999f0"
            // strokeWidth="0.537"
            ></path>
            <path d={`M 49 ${leftPoint} L 49 ${leftPoint - 25} A 5 5 0 0 1 54 ${leftPoint - 30} A 125 125 0 0 1 130 ${rightPoint - 30} A 5 5 0 0 1 135 ${rightPoint - 25} L 134 ${rightPoint} A 205 205 0 0 1 49 ${leftPoint} Z`}
                fill="#ffffff"
                stroke="#eeeeeef0"
            ></path>
            <path d='M 100 139.862 C 100 140.772 99.262 141.51 98.352 141.51 C 97.442 141.51 96.704 140.772 96.704 139.862 C 96.704 138.952 97.442 138.214 98.352 138.214 C 99.262 138.214 100 138.952 100 139.862 Z
            M 76.142 135.817 C 76.142 136.727 75.404 137.465 74.494 137.465 C 73.584 137.465 72.846 136.727 72.846 135.817 C 72.846 134.907 73.584 134.169 74.494 134.169
            C 75.404 134.169 76.142 134.907 76.142 135.817 Z M 57.719 142.648 C 57.719 143.558 56.981 144.296 56.071 144.296 C 55.161 144.296 54.423 143.558 54.423 142.648 C 54.423 141.738 55.161 141 56.071 141
            C 56.981 141 57.719 141.738 57.719 142.648 Z M 63.591 127.466 C 63.591 128.376 62.853 129.114 61.943 129.114 C 61.033 129.114 60.295 128.376 60.295 127.466 C 60.295 126.556 61.033 125.818 61.943 125.818
            C 62.853 125.818 63.591 126.556 63.591 127.466 Z M 56.591 120.466 C 56.591 121.376 55.853 122.114 54.943 122.114 C 54.033 122.114 53.295 121.376 53.295 120.466 C 53.295 119.556 54.033 118.818 54.943 118.818
            C 55.853 118.818 56.591 119.556 56.591 120.466 Z M 67.142 137.817 C 67.142 138.727 66.404 139.465 65.494 139.465 C 64.584 139.465 63.846 138.727 63.846 137.817 C 63.846 136.907 64.584 136.169 65.494 136.169
            C 66.404 136.169 67.142 136.907 67.142 137.817 Z M 73.142 145.817 C 73.142 146.727 72.404 147.465 71.494 147.465 C 70.584 147.465 69.846 146.727 69.846 145.817 C 69.846 144.907 70.584 144.169 71.494 144.169
            C 72.404 144.169 73.142 144.907 73.142 145.817 Z M 65.142 146.817 C 65.142 147.727 64.404 148.465 63.494 148.465 C 62.584 148.465 61.846 147.727 61.846 146.817 C 61.846 145.907 62.584 145.169 63.494 145.169
            C 64.404 145.169 65.142 145.907 65.142 146.817 Z M 80.142 150.817 C 80.142 151.727 79.404 152.465 78.494 152.465 C 77.584 152.465 76.846 151.727 76.846 150.817 C 76.846 149.907 77.584 149.169 78.494 149.169
            C 79.404 149.169 80.142 149.907 80.142 150.817 Z M 81.142 141.817 C 81.142 142.727 80.404 143.465 79.494 143.465 C 78.584 143.465 77.846 142.727 77.846 141.817 C 77.846 140.907 78.584 140.169 79.494 140.169
            C 80.404 140.169 81.142 140.907 81.142 141.817 Z M 62.142 112.817 C 62.142 113.727 61.404 114.465 60.494 114.465 C 59.584 114.465 58.846 113.727 58.846 112.817 C 58.846 111.907 59.584 111.169 60.494 111.169
            C 61.404 111.169 62.142 111.907 62.142 112.817 Z M 121.142 112.817 C 121.142 113.727 120.404 114.465 119.494 114.465 C 118.584 114.465 117.846 113.727 117.846 112.817 C 117.846 111.907 118.584 111.169 119.494 111.169
            C 120.404 111.169 121.142 111.907 121.142 112.817 Z M 124.142 119.817 C 124.142 120.727 123.404 121.465 122.494 121.465 C 121.584 121.465 120.846 120.727 120.846 119.817 C 120.846 118.907 121.584 118.169 122.494 118.169
            C 123.404 118.169 124.142 118.907 124.142 119.817 Z M 116.142 126.817 C 116.142 127.727 115.404 128.465 114.494 128.465 C 113.584 128.465 112.846 127.727 112.846 126.817 C 112.846 125.907 113.584 125.169 114.494 125.169
            C 115.404 125.169 116.142 125.907 116.142 126.817 Z M 129.142 126.817 C 129.142 127.727 128.404 128.465 127.494 128.465 C 126.584 128.465 125.846 127.727 125.846 126.817 C 125.846 125.907 126.584 125.169 127.494 125.169
            C 128.404 125.169 129.142 125.907 129.142 126.817 Z M 129.142 136.817 C 129.142 137.727 128.404 138.465 127.494 138.465 C 126.584 138.465 125.846 137.727 125.846 136.817 C 125.846 135.907 126.584 135.169 127.494 135.169
            C 128.404 135.169 129.142 135.907 129.142 136.817 Z M 120.142 133.817 C 120.142 134.727 119.404 135.465 118.494 135.465 C 117.584 135.465 116.846 134.727 116.846 133.817 C 116.846 132.907 117.584 132.169 118.494 132.169
            C 119.404 132.169 120.142 132.907 120.142 133.817 Z M 122.142 146.817 C 122.142 147.727 121.404 148.465 120.494 148.465 C 119.584 148.465 118.846 147.727 118.846 146.817 C 118.846 145.907 119.584 145.169 120.494 145.169
            C 121.404 145.169 122.142 145.907 122.142 146.817 Z M 110.142 149.817 C 110.142 150.727 109.404 151.465 108.494 151.465 C 107.584 151.465 106.846 150.727 106.846 149.817 C 106.846 148.907 107.584 148.169 108.494 148.169
            C 109.404 148.169 110.142 148.907 110.142 149.817 Z M 89.142 153.817 C 89.142 154.727 88.404 155.465 87.494 155.465 C 86.584 155.465 85.846 154.727 85.846 153.817 C 85.846 152.907 86.584 152.169 87.494 152.169
            C 88.404 152.169 89.142 152.907 89.142 153.817 Z M 100.142 152.817 C 100.142 153.727 99.404 154.465 98.494 154.465 C 97.584 154.465 96.846 153.727 96.846 152.817 C 96.846 151.907 97.584 151.169 98.494 151.169
            C 99.404 151.169 100.142 151.907 100.142 152.817 Z M 111.142 139.817 C 111.142 140.727 110.404 141.465 109.494 141.465 C 108.584 141.465 107.846 140.727 107.846 139.817 C 107.846 138.907 108.584 138.169 109.494 138.169
            C 110.404 138.169 111.142 138.907 111.142 139.817 Z M 90.142 145.817 C 90.142 146.727 89.404 147.465 88.494 147.465 C 87.584 147.465 86.846 146.727 86.846 145.817 C 86.846 144.907 87.584 144.169 88.494 144.169
            C 89.404 144.169 90.142 144.907 90.142 145.817 Z M 104.142 145.817 C 104.142 146.727 103.404 147.465 102.494 147.465 C 101.584 147.465 100.846 146.727 100.846 145.817 C 100.846 144.907 101.584 144.169 102.494 144.169
            C 103.404 144.169 104.142 144.907 104.142 145.817 Z M 97.142 148.817 C 97.142 149.727 96.404 150.465 95.494 150.465 C 94.584 150.465 93.846 149.727 93.846 148.817 C 93.846 147.907 94.584 147.169 95.494 147.169
            C 96.404 147.169 97.142 147.907 97.142 148.817 Z M 117.142 144.817 C 117.142 145.727 116.404 146.465 115.494 146.465 C 114.584 146.465 113.846 145.727 113.846 144.817 C 113.846 143.907 114.584 143.169 115.494 143.169
            C 116.404 143.169 117.142 143.907 117.142 144.817 Z M 123.142 141.817 C 123.142 142.727 122.404 143.465 121.494 143.465 C 120.584 143.465 119.846 142.727 119.846 141.817 C 119.846 140.907 120.584 140.169 121.494 140.169
            C 122.404 140.169 123.142 140.907 123.142 141.817 Z M 124.142 127.817 C 124.142 128.727 123.404 129.465 122.494 129.465 C 121.584 129.465 120.846 128.727 120.846 127.817 C 120.846 126.907 121.584 126.169 122.494 126.169
            C 123.404 126.169 124.142 126.907 124.142 127.817 Z M 125.142 133.817 C 125.142 134.727 124.404 135.465 123.494 135.465 C 122.584 135.465 121.846 134.727 121.846 133.817 C 121.846 132.907 122.584 132.169 123.494 132.169
            C 124.404 132.169 125.142 132.907 125.142 133.817 Z M 127.142 143.817 C 127.142 144.727 126.404 145.465 125.494 145.465 C 124.584 145.465 123.846 144.727 123.846 143.817 C 123.846 142.907 124.584 142.169 125.494 142.169
            C 126.404 142.169 127.142 142.907 127.142 143.817 Z M 83.142 146.817 C 83.142 147.727 82.404 148.465 81.494 148.465 C 80.584 148.465 79.846 147.727 79.846 146.817 C 79.846 145.907 80.584 145.169 81.494 145.169
            C 82.404 145.169 83.142 145.907 83.142 146.817 Z M 85.142 150.817 C 85.142 151.727 84.404 152.465 83.494 152.465 C 82.584 152.465 81.846 151.727 81.846 150.817 C 81.846 149.907 82.584 149.169 83.494 149.169
            C 84.404 149.169 85.142 149.907 85.142 150.817 Z M 68.142 143.817 C 68.142 144.727 67.404 145.465 66.494 145.465 C 65.584 145.465 64.846 144.727 64.846 143.817 C 64.846 142.907 65.584 142.169 66.494 142.169
            C 67.404 142.169 68.142 142.907 68.142 143.817 Z M 55.142 138.817 C 55.142 139.727 54.404 140.465 53.494 140.465 C 52.584 140.465 51.846 139.727 51.846 138.817 C 51.846 137.907 52.584 137.169 53.494 137.169
            C 54.404 137.169 55.142 137.907 55.142 138.817 Z M 56.142 132.817 C 56.142 133.727 55.404 134.465 54.494 134.465 C 53.584 134.465 52.846 133.727 52.846 132.817 C 52.846 131.907 53.584 131.169 54.494 131.169
            C 55.404 131.169 56.142 131.907 56.142 132.817 Z M 54.142 129.817 C 54.142 130.727 53.404 131.465 52.494 131.465 C 51.584 131.465 50.846 130.727 50.846 129.817 C 50.846 128.907 51.584 128.169 52.494 128.169
            C 53.404 128.169 54.142 128.907 54.142 129.817 Z M 56.142 126.817 C 56.142 127.727 55.404 128.465 54.494 128.465 C 53.584 128.465 52.846 127.727 52.846 126.817 C 52.846 125.907 53.584 125.169 54.494 125.169
            C 55.404 125.169 56.142 125.907 56.142 126.817 Z M 58.142 138.817 C 58.142 139.727 57.404 140.465 56.494 140.465 C 55.584 140.465 54.846 139.727 54.846 138.817 C 54.846 137.907 55.584 137.169 56.494 137.169
            C 57.404 137.169 58.142 137.907 58.142 138.817 Z M 68.142 140.817 C 68.142 141.727 67.404 142.465 66.494 142.465 C 65.584 142.465 64.846 141.727 64.846 140.817 C 64.846 139.907 65.584 139.169 66.494 139.169
            C 67.404 139.169 68.142 139.907 68.142 140.817 Z M 66.142 148.817 C 66.142 149.727 65.404 150.465 64.494 150.465 C 63.584 150.465 62.846 149.727 62.846 148.817 C 62.846 147.907 63.584 147.169 64.494 147.169
            C 65.404 147.169 66.142 147.907 66.142 148.817 Z M 79.142 152.817 C 79.142 153.727 78.404 154.465 77.494 154.465 C 76.584 154.465 75.846 153.727 75.846 152.817 C 75.846 151.907 76.584 151.169 77.494 151.169
            C 78.404 151.169 79.142 151.907 79.142 152.817 Z M 107.142 151.817 C 107.142 152.727 106.404 153.465 105.494 153.465 C 104.584 153.465 103.846 152.727 103.846 151.817 C 103.846 150.907 104.584 150.169 105.494 150.169
            C 106.404 150.169 107.142 150.907 107.142 151.817 Z M 113.142 149.817 C 113.142 150.727 112.404 151.465 111.494 151.465 C 110.584 151.465 109.846 150.727 109.846 149.817 C 109.846 148.907 110.584 148.169 111.494 148.169
            C 112.404 148.169 113.142 148.907 113.142 149.817 Z M 113.142 142.817 C 113.142 143.727 112.404 144.465 111.494 144.465 C 110.584 144.465 109.846 143.727 109.846 142.817 C 109.846 141.907 110.584 141.169 111.494 141.169
            C 112.404 141.169 113.142 141.907 113.142 142.817 Z M 121.296 139.817 C 121.296 140.727 120.558 141.465 119.648 141.465 C 118.738 141.465 118 140.727 118 139.817 C 118 138.907 118.738 138.169 119.648 138.169
            C 120.558 138.169 121.296 138.907 121.296 139.817 Z M 119.296 137.817 C 119.296 138.727 118.558 139.465 117.648 139.465 C 116.738 139.465 116 138.727 116 137.817 C 116 136.907 116.738 136.169 117.648 136.169
            C 118.558 136.169 119.296 136.907 119.296 137.817 Z M 113.296 137.817 C 113.296 138.727 112.558 139.465 111.648 139.465 C 110.738 139.465 110 138.727 110 137.817 C 110 136.907 110.738 136.169 111.648 136.169
            C 112.558 136.169 113.296 136.907 113.296 137.817 Z M 115.296 136.817 C 115.296 137.727 114.558 138.465 113.648 138.465 C 112.738 138.465 112 137.727 112 136.817 C 112 135.907 112.738 135.169 113.648 135.169
            C 114.558 135.169 115.296 135.907 115.296 136.817 Z'
                fill="#ffffff"
                stroke="#ffe743"
                strokeWidth={0.5}
            ></path>
            <path d={`M 49 ${leftPoint} A 205 205 0 0 0 134 ${rightPoint} L 134 147 A 75 75 0 0 1 49 147 Z`}
                fill="#e97305c0"
                stroke="#ba7433c0"
            ></path>
            <path d='M 48 41 L 49 16 A 125 125 0 0 0 136 16 L 136 148 A 75 75 0 0 1 48 148 Z'
                fill="url(#glass-gradient-inner)"
                strokeWidth={1.2}
            ></path>
        </svg>
    )
}