import Wick from "./Wick";

export default function Candle2({ lit, onClick }) {
  return (
    <div className="candle-2">
      <Wick candle='two' lit={lit} onClick={onClick}/>
      <svg id='candle-two-svg' className='candle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <path d="M 43 49 L 29 62 A 31 28 0 0 1 50 12 A 28 28 0 0 1 78 40 A 32 32 0 0 1 62 66 L 78 69 L 75 87 L 24 89 L 55 44 A 7 7 0 0 0 44 37 A 8 8 0 0 0 43 49 Z" fill='#fffffa' stroke='#b00' strokeWidth={4} strokeLinejoin="round"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="orange" transform-origin='29.2877% 30.0682%' transform="matrix(0.811086, -0.584927, 0.584927, 0.811086, -1.647546, 0.754169)"></path >
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="pink" transform-origin="35.621px 27.896px" transform="matrix(0.992903, -0.118926, 0.118926, 0.992903, 19.760118, -2.250414)"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="blue" transform-origin="35.621px 27.896px" transform="matrix(0.992903, -0.118926, 0.118926, 0.992903, 27.216311, 14.006537)"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="red" transform-origin="35.621px 27.896px" transform="matrix(0.992903, -0.118926, 0.118926, 0.992903, -6.477958, 17.011122)"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="green" transform-origin="35.621px 27.896px" transform="matrix(0.955235, -0.295847, 0.295847, 0.955235, 17.183145, 31.175595)"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="yellow" transform-origin="35.621px 27.896px" transform="matrix(0.955235, -0.295847, 0.295847, 0.955235, 4.520967, 49.20311)"></path>
        <path d="M 37.193 25.787 L 38.307 28.174 L 40.987 28.466 L 38.995 30.233 L 39.538 32.801 L 37.193 31.506 L 34.848 32.801 L 35.391 30.233 L 33.399 28.466 L 36.079 28.174 Z" fill="purple" transform-origin="35.621px 27.896px" transform="matrix(0.997549, -0.069978, 0.069978, 0.997549, 28.87957, 48.080339)"></path>
      </svg >
    </div>
  );
}